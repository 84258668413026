<div
  #container
  [ngClass]="[
    'opacity-0 -translate-y-full transition-all duration-500 transform flex gap-2 items-center p-4 rounded-lg shadow min-w-[200px] whitespace-normal',
    toast()?.type === 'toast-success' ? 'bg-green-600 text-white' : '',
    toast()?.type === 'toast-danger' ? 'bg-red-600 text-white' : '',
    toast()?.type === 'toast-warning' ? 'bg-yellow-500 text-white' : '',
    toast()?.type === 'toast-info' ? 'bg-blue-600 text-white' : '',
    toast()?.type === 'toast-default' ? 'bg-gray-800 text-white' : ''
  ]"
  role="alert"
>
  <app-font-icon [icon]="$any(toast()?.type)" [color]="'white'" class="shrink-0"></app-font-icon>
  <div class="text-sm font-normal flex-grow">{{ toast()?.content }}</div>
  <button
    (click)="onClose()"
    type="button"
    class="btn-close text-white hover:text-gray-200 shrink-0"
    data-dismiss-target="#toast-default"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <app-font-icon [icon]="'close'" [color]="'white'"></app-font-icon>
  </button>
</div>
